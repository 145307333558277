import { toSignal } from '@angular/core/rxjs-interop';
import { QueryObserverResult, createSuccessObserverResult, filterSuccessResult } from '@ngneat/query';
import { Observable, map } from 'rxjs';

export interface Pagination {
  page: number;
  count: number;
  pages: number;
  last: number;
  first_url: string;
  next_url: string;
  last_url: string;
  page_url: string;
  prev_url: string;
}

export interface Paginated<T> {
  data: T[];
  pagination: Pagination;
}

export function createEmptyPaginatedResponse<T>(): Paginated<T> {
  return {
    data: [],
    pagination: {
      page: 1,
      count: 0,
      pages: 1,
      last: 1,
      first_url: '',
      next_url: '',
      last_url: '',
      page_url: '',
      prev_url: '',
    },
  };
}

export function withPaginatedResponse<T>(result$: Observable<QueryObserverResult<Paginated<T>>>) {
  const result = toSignal(result$, {
    initialValue: createSuccessObserverResult(createEmptyPaginatedResponse<T>()),
  });

  const list$ = result$.pipe(
    filterSuccessResult(),
    map((query) => query.data),
  );
  const list = toSignal(list$, { initialValue: createEmptyPaginatedResponse<T>() });

  return { result, result$, list, list$ };
}
